<template>
  <div class="product-center">
    <div class="product-center__container">
      <p class="title">
        产品中心<span class="desc">（高亮卡片为账号已有权限的产品，灰色卡片为账号暂无权限的产品）</span>
      </p>
      <ul class="product-list">
        <li class="product" v-for="(prod, pIndex) in productList" :key="pIndex">
          <img :src="prod.cover" class="product-img" />
          <div class="produce-detail">
            <p class="produce-detail__name">{{ prod.name }}</p>
            <p class="produce-detail__desc">{{ prod.desc }}</p>
            <template v-if="prod.type == 'dueDiligenceReport'">
              <p class="produce-detail__button" @click="prod.cb">{{ info.applyText }}</p>
            </template>
            <template v-else-if="prod.type == 'patentReport'">
              <p class="produce-detail__button" @click="prod.cb">{{ patentInfo.applyText }}</p>
            </template>
            <template v-else-if="prod.type == 'newPatentReport'">
              <p class="produce-detail__button" @click="prod.cb">{{ newPatentInfo.applyText }}</p>
            </template>
            <template v-else-if="prod.type == 'aerialview'">
              <p class="produce-detail__button" @click="prod.cb">{{ aerialviewInfo.applyText }}</p>
            </template>
            <template v-else-if="prod.type == 'hardReport'">
              <p :class="{ 'produce-detail__button': true, 'disable_button': HardReportInfo.disabled }"
                @click="prod.cb">{{
                  HardReportInfo.applyText }}</p>
            </template>
            <template v-else>
              <p class="produce-detail__button" @click="prod.cb">{{ prod.btn }}</p>
            </template>
          </div>
        </li>
      </ul>
    </div>
    <!-- 科技尽调报告 -->
    <el-dialog :title="info.modalType === 'apply' ? '申请成功' : '升级会员'" :visible.sync="info.modalVisible" width="590px"
      class="custom_dialog">
      <template v-if="info.modalType === 'apply'">
        权益时限为30天，可下载10份报告<br />
        有任何疑问及需求，请扫码联系客服
      </template>
      <template v-else>
        {{ info.modalContent }}
      </template>
      <div style="display: flex;justify-content: center;align-items: center;">
        <img src="https://images.zzt.com.cn/3u/2022/07/22/adviser-service-qrcode.png" width="140">
      </div>
      <el-button @click="info.modalVisible = false" style="margin-top: 12px;">关闭</el-button>
    </el-dialog>
    <!-- 专利报告(旧版) -->
    <el-dialog :title="patentInfo.modalType === 'apply' ? '申请成功' : '升级会员'" :visible.sync="patentInfo.modalVisible"
      width="590px" class="custom_dialog">
      <template v-if="patentInfo.modalType === 'apply'">
        权益时限为30天，可下载10份报告<br />
        有任何疑问及需求，请扫码联系客服
      </template>
      <template v-else>
        {{ patentInfo.modalContent }}
      </template>
      <div style="display: flex;justify-content: center;align-items: center;">
        <img src="https://images.zzt.com.cn/3u/2022/07/22/adviser-service-qrcode.png" width="140">
      </div>
      <el-button @click="patentInfo.modalVisible = false" style="margin-top: 12px;">关闭</el-button>
    </el-dialog>
    <!-- 专利报告（新版） -->
    <el-dialog :title="newPatentInfo.modalType === 'apply' ? '申请成功' : '升级会员'" :visible.sync="newPatentInfo.modalVisible"
      width="590px" class="custom_dialog">
      <template v-if="newPatentInfo.modalType === 'apply'">
        权益时限为30天，可下载10份报告<br />
        有任何疑问及需求，请扫码联系客服
      </template>
      <template v-else>
        {{ newPatentInfo.modalContent }}
      </template>
      <div style="display: flex;justify-content: center;align-items: center;">
        <img src="https://images.zzt.com.cn/3u/2022/07/22/adviser-service-qrcode.png" width="140">
      </div>
      <el-button @click="newPatentInfo.modalVisible = false" style="margin-top: 12px;">关闭</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { getApplyRegisterRights, getRightsInfo, getTecReportLimit, getPatentApplyRegisterRights, getPatentRightsInfo, getPatentReportLimit, getNewPatentApplyRegisterRights, getNewPatentRightsInfo, getNewPatentReportLimit, getAerialviewLimit, checkRights } from '@/api'
export default {
  name: 'ProductCenter',
  data() {
    return {
      info: {
        name: '',
        tecLoading: true,
        tecRights: false,
        applyText: '',
        modalVisible: false,
        modalType: 'success',
        modalContent: '',
      },
      patentInfo: {
        name: '',
        patentLoading: true,
        patentRights: false,
        applyText: '预览报告',
        modalVisible: false,
        modalType: 'success',
        modalContent: '',
      },
      newPatentInfo: {
        name: '',
        patentLoading: true,
        patentRights: false,
        applyText: '立即申请',
        modalVisible: false,
        modalType: 'success',
        modalContent: '',
      },
      aerialviewInfo: {
        applyText: '立即申请',
        aerialviewRights: false,
      },
      HardReportInfo: {
        disabled: false,
        applyText: '查看报告',
      },
      productList: [
        {
          name: '深眸',
          desc: '科技企业成长性与科技力评测系统',
          cover: 'https://images.zzt.com.cn/officialWebsite/patE.png',
          btn: '点击进入',
          cb: () => window.open(`${process.env.VUE_APP_JUMPURL}enterprise/search?token=${this.$store.state.token}`)
        },
        {
          name: '瞰见',
          type: 'aerialview',
          desc: '透视特定区域、关键产业的高价值科技企业分布，提供科技、人才、融资等多维数据洞察，帮助银行进行科技企业梯队培育与精准滴灌',
          cover: 'https://images.zzt.com.cn/officialWebsite/aerialview.png',
          btn: '点击进入',
          cb: () => {
            if (this.aerialviewInfo.aerialviewRights) {
              //点击进入
              window.open(`${process.env.VUE_APP_AERIALVIEWURL}aerialview/region?token=${this.$store.state.token}`)
            } else {
              //跳转填写资料
              this.$router.push({ path: '/aerialView#trial' })
            }
          }
        },
        // {
        //   name: '中国企业专利价值评估报告',
        //   type: 'patentReport',
        //   desc: '中国企业专利价值评估报告：包括企业基本信息、知识产权等，侧重展示企业专利实力与价值',
        //   cover: 'https://images.zzt.com.cn/ox/2023/04/06/book1.png',
        //   btn: '',
        //   cb: async () => {
        //     if (this.patentInfo.patentRights) {
        //       window.open(`${process.env.VUE_APP_JUMPURL}enterprise/search?token=${this.$store.state.token}`)
        //     } else {
        //       if (this.patentInfo.modalType === 'apply') {
        //         const { success } = await getPatentApplyRegisterRights()
        //         if (success) {
        //           this.patentInfo.modalVisible = true
        //           this.patentInfo.patentRights = true
        //           this.patentInfo.applyText = '预览报告'
        //         }
        //       } else {
        //         this.patentInfo.modalVisible = true
        //       }
        //     }
        //   }
        // },        
        {
          name: '企业专利评价报告',
          type: 'newPatentReport',
          desc: '中国知识产权出版社、中知数通（北京）信息技术有限公司联合出品，可应用于企业专利质押融资、转让等场景。',
          cover: 'https://images.zzt.com.cn/ox/2023/04/06/book1.png',
          btn: '',
          cb: async () => {
            if (this.newPatentInfo.patentRights) {
              // window.open(`${process.env.VUE_APP_JUMPURL}enterprise/search?token=${this.$store.state.token}`)
              //todo 替换新的样例模版
              window.open('https://images.zzt.com.cn/gnW/2024/06/19/广东健齿生物科技有限公司-40项发明专利评价报告.pdf')
            } else {
              if (this.newPatentInfo.modalType === 'apply') {
                const { success } = await getNewPatentApplyRegisterRights()
                if (success) {
                  this.newPatentInfo.modalVisible = true
                  this.newPatentInfo.patentRights = true
                  this.newPatentInfo.applyText = '查看样例'
                }
              } else {
                this.newPatentInfo.modalVisible = true
              }
            }
          }
        },
        {
          name: '中国企业科技尽调报告',
          type: 'dueDiligenceReport',
          desc: '包含企业赛道、科技实力、科研团队、知识产权风险等模块，支持信审和尽调等场景。',
          cover: 'https://images.zzt.com.cn/ox/2023/04/06/book2.png',
          btn: '',
          cb: async () => {
            if (this.info.tecRights) {
              // window.open(`${process.env.VUE_APP_JUMPURL}enterprise/search?token=${this.$store.state.token}`)
              window.open('https://images.zzt.com.cn/gnW/2024/06/18/广东万丈金数信息技术股份有限公司-中国企业科技尽调报告_V20240823.pdf')
            } else {
              if (this.info.modalType === 'apply') {
                const { success } = await getApplyRegisterRights()
                if (success) {
                  this.info.modalVisible = true
                  this.info.tecRights = true
                  this.info.applyText = '查看样例'
                }
              } else {
                this.info.modalVisible = true
              }
            }
          }
        },
        {
          name: '企业硬科技属性评价报告',
          type: 'hardReport',
          desc: '工信部产业发展促进中心、中知数通联合出品。报告依据《企业硬科技属性评价规范》制定，支持企业硬科技属性评估线上化，智能化。',
          cover: 'https://images.zzt.com.cn/officialWebsite/hardTechnologyReport.png',
          btn: '查看报告',
          cb: async () => {
            if (!this.HardReportInfo.disabled) {
              const route = this.$router.resolve({
                path: '/hardTecReportList'
              })
              window.open(route.href, '_blank')
            }
          }
        }
      ]
    }
  },
  methods: {
    /**
 * 具体的权益逻辑见流程图：https://images.zzt.com.cn/ox/2023/04/27/power.png
 */
    async getReportRights() {
      const { data: { message }, success } = await getTecReportLimit()
      this.info.tecLoading = false
      if (success) {
        // 允许访问
        this.info.tecRights = true
        this.info.applyText = '查看样例'
      } else {
        const { data: { data } } = await getRightsInfo()
        if (!data.everContainsRegisterRights) {
          // 没申请过
          this.info.tecRights = false
          this.info.modalType = 'apply'
          this.info.applyText = '立即申请'
        } else {
          this.info.modalType = 'uplevel'
          this.info.modalContent = message
          this.info.applyText = '升级会员'
        }
      }
    },
    //专利报告（旧版）
    async getPatentReportRights() {
      const { data: { message }, success } = await getPatentReportLimit()
      this.patentInfo.patentLoading = false
      if (success) {
        // 允许访问
        this.patentInfo.patentRights = true
        this.patentInfo.applyText = '预览报告'
      } else {
        const { data: { data } } = await getPatentRightsInfo()
        if (!data.everContainsRegisterRights) {
          // 没申请过
          this.patentInfo.patentRights = false
          this.patentInfo.modalType = 'apply'
          this.patentInfo.applyText = '立即申请'
        } else {
          this.patentInfo.modalType = 'uplevel'
          this.patentInfo.modalContent = message
          this.patentInfo.applyText = '升级会员'
        }
      }
    },
    //专利报告（新版）
    async getNewPatentReportRights() {
      const { data: { message }, success } = await getNewPatentReportLimit()
      this.newPatentInfo.patentLoading = false
      if (success) {
        // 允许访问
        this.newPatentInfo.patentRights = true
        this.newPatentInfo.applyText = '查看样例'
      } else {
        const { data: { data } } = await getNewPatentRightsInfo()
        if (!data.everContainsRegisterRights) {
          // 没申请过
          this.newPatentInfo.patentRights = false
          this.newPatentInfo.modalType = 'apply'
          this.newPatentInfo.applyText = '立即申请'
        } else {
          this.newPatentInfo.modalType = 'uplevel'
          this.newPatentInfo.modalContent = message
          this.newPatentInfo.applyText = '升级会员'
        }
      }
    },
    //瞰见
    async getAerialviewLimit() {
      const { success } = await getAerialviewLimit()
      if (success) {
        // 允许访问
        this.aerialviewInfo.aerialviewRights = true
        this.aerialviewInfo.applyText = '点击进入'
      } else {
        // 没申请过
        this.aerialviewInfo.aerialviewRights = false
        this.aerialviewInfo.applyText = '立即申请'
      }
    },
    //硬科技报告
    async checkHardReportRights() {
      const { success } = await checkRights()
      if (success) {
        // 允许访问
        this.HardReportInfo.disabled = false
      } else {
        // 没申请过
        this.HardReportInfo.disabled = true
      }
    }
  },
  mounted() {
    this.getReportRights()
    // this.getPatentReportRights()
    this.getNewPatentReportRights()
    this.getAerialviewLimit()
    this.checkHardReportRights()
  }
}
</script>

<style lang="scss" scoped>
.product-center {
  background: #f5f6f9;
  padding-top: Translate(46px);

  &__container {
    width: Translate(1200px);
    margin: 0 auto;

    .title {
      font-size: Translate(20px);
      color: #333333;
      font-weight: 500;
      text-align: start;

      .desc {
        font-size: Translate(14px);
        color: #999999;
        font-weight: 400;
      }
    }

    .product-list {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      margin-top: Translate(20px);
      padding: 0;

      .product {
        display: flex;
        justify-content: space-between;
        width: Translate(386px);
        height: Translate(200px);
        background: #ffffff;
        border-radius: Translate(5px);
        box-sizing: border-box;
        padding: Translate(18px) Translate(14px) Translate(20px) Translate(18px);
        margin-bottom: Translate(20px);

        &:nth-child(3n+2) {
          margin-left: Translate(20px);
          margin-right: Translate(20px);
        }

        img {
          width: Translate(120px);
          height: Translate(162px);
        }

        .produce-detail {
          position: relative;
          width: Translate(220px);

          &__name {
            margin-top: Translate(8px);
            font-size: Translate(18px);
            color: #000000;
            font-weight: 500;
            text-align: start;
          }

          &__desc {
            margin-top: Translate(8px);
            font-size: Translate(14px);
            color: #666666;
            font-weight: 400;
            text-align: start;
            line-height: Translate(20px);
          }

          &__button {
            position: absolute;
            right: 0;
            bottom: 0;
            background: #4088eb;
            border-radius: Translate(4px);
            width: Translate(88px);
            height: Translate(32px);
            line-height: Translate(20px);
            box-sizing: border-box;
            padding: Translate(6px) Translate(16px);
            font-size: Translate(14px);
            color: #ffffff;
            text-align: center;
            font-weight: 400;
            cursor: pointer;

            &:hover {
              background-color: #337ee5;
            }
          }

          .disable_button {
            opacity: 0.3;
            background: #4088EB;
            cursor: no-drop;
          }

          .title_skeleton {
            position: relative;
            width: 60%;
            height: Translate(20px);
            right: 50%;
            translate: 50%;
          }

          .text_skeleton {
            margin-top: 6px;
            height: Translate(20px);
          }

          .button_skeleton {
            width: Translate(88px);
            height: Translate(32px);
            position: relative;
            top: Translate(22px);
            right: -50%;
            translate: -50%;
          }
        }
      }
    }
  }
}

.no-permissions {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
  }
}
</style>
